/* Orders.module.css */

/* Container for both heading and orders content */
.container {
    margin-top: 0;
    padding-top: 0;
}

/* Heading box fixed just below the navbar */
.headingBox {
    position: fixed;
    top: 70px;
    /* Adjust this according to the height of your navbar */
    left: 0;
    right: 0;
    background-color: white;
    padding-top:100px;
    z-index: 999;
    text-align: left;
    padding-left: 56px;
    padding-bottom: 18px;
}

.heading{
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.06em;
    color: #333333;
    width: 100%;
}

.orders-container{
    margin-top:245px;
}

/* Apply the font to the orders container */
.order-container {
    max-width: 1204px;
    font-family: "Poppins", sans-serif;
    margin:auto;
    margin-top:29px;
    margin-bottom:29px ;
    border:1px solid #848484;
    border-radius:6px;
    position: relative;
}

.order-text {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.88px;
    color: #333333;
}

.order-transaction {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #00000099;
}

.order-total {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #00000099;
}

.transaction-date-time {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.88px;
    text-align: left;
    color: #00000099;
}

/* Default: hide the element */
.t-d-t-lg {
    position: absolute;
    top: 30px;
    right: 24px;
    width: 150px;
    height: 15px;
    opacity: 1;
    /* Set to visible (opacity 1, instead of 0) */
    text-align: right;
}

/* Default: hide the element */
.t-d-t-sm {
    display: none;
}

.reciept {
    padding: 18px 17px;
    border-radius: 4px;
    border: 1px solid #00A7B5;
    opacity: 1;
    background-color: rgba(0, 167, 181, 0.08);
    text-align: center;
}

.reciept-text{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.04em;
    color: #00A7B5;
    cursor: pointer;
}

@media(max-width:1328px) {
    .headingBox {
        padding-left: 3px;
    }
}

/* Media query for screens smaller than 550px */
@media(max-width: 768px) {
    .t-d-t-lg {
        display: none;
    }

    .t-d-t-sm {
        display: block;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #00000099;
    }

    .headingBox {
        top: 60px;
        padding-top: 50px;
    }

    .orders-container {
        margin-top: 160px;
    }
}

/* Media query for screens smaller than 550px */
@media(max-width: 550px) {
    .t-d-t-lg {
        display: none;
    }

    .t-d-t-sm {
        display: block;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #00000099;
    }

    .order-text,
    .order-transaction,
    .order-total,
    .transaction-date-time {
        font-size: 10px;
        letter-spacing: 0.01em;
    }

    .heading {
        font-size: 20px;
    }

    .headingBox {
        top: 60px;
        padding-top: 30px;
    }

    .orders-container {
        margin-top: 140px;
    }
}