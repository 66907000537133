.container {
  background: var(--secondary-bacground);

  padding-top: 2.8rem;
  height: 82vh;
}

.wrapper {
  width: 100%;
  margin: auto;
  height: 100%;

}


.myContainerClass {
  height: 40px;
}

.banner_Section {
  margin-top: 0.5rem;
  height: 100%;
  width: 100%;
}

.inputWrapper {
  width: 100%;
}

.banner_Text {
  font-family: "Poppins", sans-serif;
  font-size: var(--banner-primary-fontSize-desk);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--banner-desk-fontColor);
}

.banner_Text2 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--banner-desk-fontColor);
}

.login_Form {
  position: absolute;
  top: 0;
  width: 90%;
  height: 100%;

}

.dynamic_Pink {
  color: var(--pink);
  font-family: "Poppins", sans-serif;
  font-size: var(--banner-primary-fontSize-desk);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dynamic_Green {
  color: var(--green);
  font-family: "Poppins", sans-serif;
  font-size: var(--banner-primary-fontSize-desk);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.banner_Sub_Text {
  margin-top: 1rem;
  color: var(--deep-grey);
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.TermsAndCondition {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
}

.logoFlex {
  display: flex;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  gap: 1rem;
  align-items: center;

     
}

.checkbox {
  display: flex;
  margin-bottom: -1px;
  align-items: center;
  /* border: 1px solid rgb(0, 255, 38); */
}

.buttonText {
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.banner_image {
  width: 100%;
  /* border: 1px solid rgb(0, 26, 255); */
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.banner_image {
  width: 100%;
  margin: auto;
}

.banner_image2 img {
  /* border: 1px solid green; */
  /* height: 100vh; */
  margin-top: -5rem;
  width: 100%;
}

.banner_image2 {
  width: 100%;
  margin: auto;
}

.banner_image3 img {
  /* border: 1px solid green; */
  margin-top: -5rem;
  width: 100%;
}

.banner_image3 {
  width: 100%;
  margin: auto;
}

.login_Section {

  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;


}

.login_Section1 {
  margin-left: 3rem;
  position: absolute;
  top: 0;
  width: 100%;
}

.alert_Text {
  margin-top: 1rem;
  font-weight: 1000;
  font-size: 1rem;
  color: red;
}

.banner_image {
  display: flex;
  justify-content: space-around;
  /* gap: 2rem;  */
}

.banner_left_images {
  /* width: 500px; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46%;
}

.main_image {
  border: 2px dashed #5b6c78;
  width: 405px;
  height: 405px;
  border-radius: 50%;
  padding: 10%;
  display: flex;
  justify-content: center;
  position: relative;
}

.circle_image1 {
  width: 90px;
  height: 90px;
  position: absolute;
  top: -13%;
  left: 38%;
  border-radius: 50%;
}

.circle_image2 {
  width: 90px;
  height: 90px;
  position: absolute;
  bottom: -7%;
  left: 8%;
  border-radius: 50%;
}

.circle_image3 {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 15%;
  left: -10%;
  border-radius: 50%;
}

.circle_image4 {
  width: 90px;
  height: 90px;
  position: absolute;
  bottom: -7%;
  right: 8%;
  border-radius: 50%;
}

.circle_image5 {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 15%;
  right: -10%;
  border-radius: 50%;
}

.client_logo_style {

  border-radius: 8px;
  padding: 14px 23px;
  background: #fff;
  box-shadow: 2px 4px 15.1px 0px rgba(0, 0, 0, 0.04);
  width: 177px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
}

.client_logo_style img {
  width: 177px;
  height: 60px;
  object-fit: scale-down;

}

.poco_logo_style {
  border-radius: 8px;
  padding: 14px 23px;
  background: #fff;
  box-shadow: 2px 4px 15.1px 0px rgba(0, 0, 0, 0.04);
  width: 177px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.login_grp{
  display: flex;
  flex-direction: column;
  gap: .2rem;

}
.input_style {
  border-radius: 8px;
  background: #fff;
  display: flex;
  width: 100%;
  padding: 8px 24px;
  align-items: center;
  gap: 10px;
  border: 1px solid rgb(180, 172, 172);
  outline: none;
}
.input_style:focus {
  outline: 2px solid #3182ce; 
}
.text_style {
  color: #5b6c78;

  font-family: Poppins,serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

}

/* //// */
@media (max-width: 1245px) {
  .banner_image2 img {
    margin-top: 20rem;
  }

  .banner_image3 img {
    margin-top: 15rem;
  }
}

@media (max-width: 1000px) {
  .banner_Text {
    font-size: 2.25rem;
  }

  .container {
    height: auto;
  
  }

  .banner_image {
    flex-direction: column;
    gap: 1rem;

 
  }

  .login_Section {
    width: 100%;
   
  }

  .banner_left_images {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .banner_Text2 {
    font-size: 2.125rem;
  }

  .banner_Sub_Text {
    font-size: 1.5rem;
  }

  .dynamic_Pink {
    font-size: 2.25rem;
  }

  .dynamic_Green {
    font-size: 2.25rem;
  }

  .buttonText {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {

  .login_grp {
    width: 90%;
  }

  .banner_Heading {

    width: 100%;

 
  }

  .logoFle {
    margin-left: -5rem;
  }

  .login_Section1 {
    margin-left: 0.5rem;
  }

  .login_Section {
    padding: 1rem;
    word-wrap: break-word;
    margin-left: 1vw;
  }

  .banner_image2 img {
  

    margin-top: 18rem;
  }

  .banner_Text {
    font-size: 2.125rem;
  }

  .banner_Text2 {
    font-size: 2.125rem;
  }

  .dynamic_Pink {
    font-size: 2.125rem;
  }

  .dynamic_Green {
    font-size: 2.125rem;
  }

  .banner_Sub_Text {
    font-size: 1.5rem;
  }

  .buttonText {
    font-size: 1rem;
  }



  .invalidInput {
    border-color: red !important;
  }
}

@media (max-width: 620px) {
  .banner_Text {
    font-size: 1.875rem;
  }

  .banner_Text2 {
    font-size: 1.875rem;
  }

  .dynamic_Pink {
    font-size: 1.875rem;
  }

  .dynamic_Green {
    font-size: 1.875rem;
  }

  .banner_Sub_Text {
    font-size: 1.25rem;
  }

  .buttonText {
    font-size: 1rem;
  }
}

@media (max-width: 738px) {
  .logoFlex {
    display: flex;
    width: 50%;

    align-items: center;
    margin-top: 0.8rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 738px) {
  .logoFlex {
    width: 70%;
 
  }
}

@media (max-width: 540px) {
  .main_image {
    width: 250px;
    height: 250px;
    padding: 10%;
  }

  .banner_Heading {

    width: 100%;
    height: 100%;
   
  }

  .circle_image1 {
    width: 50px;
    top: -20%;
  }

  .circle_image2,
  .circle_image3,
  .circle_image4,
  .circle_image5,
  .circle_image1 {
    width: 60px;
    height: 60px;
  }

 
  .banner_Text {
    font-size: 1.25rem;
  }

  .dynamic_Pink {
    font-size: 1.25rem;
  }

  .dynamic_Green {
    font-size: 1.25rem;
  }

  .banner_Sub_Text {
    font-size: 1.25rem;
  }

  .client_logo_style {
    border-radius: 5px;
    padding: 6px 15px;

    width: 120px;
    height: 40px;
  }

  .client_logo_style img {
    width: 120px;
    height: 40px;
  }

  .poco_logo_style {
    border-radius: 5px;
    padding: 6px 15px;

    width: 120px;
    height: 40px;
  }
}

@media (max-width: 472px) {
  .alert_Text {
    font-size: 1rem;
  }

  .banner_image2 img {
    /* border: 1px solid green; */

    margin-top: 28rem;
  }

  .buttonText {
    letter-spacing: 1.1px;
  }

  .banner_Sub_Text {
    margin-top: 0.875rem;
  }
}

@media (max-width: 400px) {
  .banner_Heading h2 {
    font-size: medium;
    font-weight: 700;
  }

  .banner_Text2 {
    font-size: 1.1rem;
  }

  .banner_image2 img {
    /* border: 1px solid green; */
    margin-top: 29rem;
  }

  .banner_image3 img {
    margin-top: 19rem;
  }
}