.wrapper {
    margin-top: 5rem;
    width: 100%;
    overflow-x: hidden;
}

.container {
    width: 95%;
    /* border: 1px solid rgb(220, 217, 24); */
    margin: auto;
    height: 100%;
}

.TermsAndCondition {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;
    /* border: 1px solid red; */
}

html,
body {
    scroll-behavior: smooth;

}

.checkbox {
    display: flex;
    margin-bottom: -1px;
    align-items: center;
    /* border: 1px solid rgb(0, 255, 38); */
}

@media(max-width:500px) {
    .wrapper {
        margin-top: 4.313rem;
    }
}