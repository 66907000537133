.main {
    display: flex;
    width: 100%;
    margin: 10%;
    max-width: 502px;
    max-height: 421px;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 5px;
}

.child {
    /* border: solid red 2px; */
    min-width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;

}

.img {
    max-width: 150px;

}

.successText {
    font-family: Source Sans Pro;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #01A601;
    text-align: center;
}

.paymentDetails {
    justify-content: space-around;
    gap: 3%;
    width: 70%;
    margin: auto;
    font-family: Source Sans Pro;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.01em;

}

.btn {
    background-color: #00A7B5 !important;
}

.btnTxt {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.53px;
    text-align: center;
    color: white;
}