.insurance_wrapper {
    position: relative;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 20px;



}

.withBorder_gray {
    margin-top: 10px;
    border-bottom: 1px solid #E1E6EB;
}

.withoutBorder {
    border-bottom: 0px;
}

.insurance_container {
    padding: 20px;
}

.subTitle {
    font-size: 1.1rem;
    font-weight: 600;
    color: #008470
}

.insurance_field_key {
    font-family: sans-serif;
    color: #6F6F6F;
    font-size: .875rem;

}

.gridSystem {
    grid-template-columns: repeat(4, 1fr);
}

.insurance_field_value {

    padding: 0;
    font-family: sans-serif;
    color: #1C2B2F;
    font-size: 1rem;
    font-weight: 500;

}

.hoverEffect:hover {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    opacity: 1;
}

.editIcon {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;

}

.addInsuranceDet {

    color: #008470;
    cursor: pointer;
    font: 1rem;
    font-weight: 500;
}

.insuranceCard {
    display: flex;
    align-items: center;
}

@media(max-width:1068px) {
    .gridSystem {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width:420px) {
    .gridSystem {
        grid-template-columns: repeat(1, 1fr);
    }
}