.main {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    margin: 10%;
    max-width: 502px;
    max-height: 421px;
    flex-direction: column;
    justify-content: center;
    background: white;
    border-radius: 10px;
}

.basicText {
    max-width: 372px;
    height: 47px;
    gap: 0px;
    opacity: 1px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #919598;
    margin-top: 20px;
    margin-bottom: 20px;
}

.timerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 159px;
    height: 159px;
    /* border: solid red 2px; */

}

.timerDisplay {
    flex-direction: column;
    font-size: 50rem;
    color: #29A972;
}

.timeleftText {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #407BFF;
}

/* .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.spinner {

    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #29A972;
    border-right-color: rgba(56, 140, 203, 0.9);
    border-bottom-color: rgba(64, 123, 255, 0.85);
    border-left-color: rgba(240, 56, 156, 0.8);
    border-left-color: rgba(240, 56, 156, 0.8);
    display: flex;
    background-clip: content-box;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */